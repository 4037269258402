const Default = (props) => {
  const { name, caption, onChange } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: 20,
        borderRadius: 10,
        background: "#FFFF89",
        border: "1px solid #000",
      }}
    >
      <input
        type="text"
        name={name}
        placeholder={caption}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        style={{
          backgroundColor: "none",
          background: "none",
          border: "none",
          fontSize: 18,
        }}
      />
    </div>
  );
};

export { Default as Input };
