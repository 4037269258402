const Default = (props) => {
  const { caption } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 10,
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: 25,
          height: 25,
          borderRadius: 6,
          background: "#FFFF89",
        }}
      />
      <p
        style={{
          fontSize: 20,
          fontFamily: "Inter",
          lineHeight: "1rem",
        }}
      >
        {caption}
      </p>
    </div>
  );
};

export { Default as TextDot };
