const Default = (props) => {
  const { caption, simple, style, ...other } = props;

  return (
    <p
      style={{
        fontSize: 50,
        ...(!simple && { marginTop: 200 }),
        fontFamily: "Tablon bold",
        fontWeight: "bold",
        background: "linear-gradient(90.12deg, #FFFF89 -4.11%, #FFFFFF 90.1%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        ...style,
      }}
      {...other}
    >
      {caption}
    </p>
  );
};

export { Default as Caption };
