const Default = (props) => {
  const { description, caption } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <p
          style={{
            fontSize: 20,
            fontWeight: "bold",
            color: "#FFFF91",
            fontFamily: "Inter",
            lineHeight: "2rem",
            flexGrow: 1,
          }}
        >
          {caption}
        </p>

        <div
          style={{
            height: 4,
            backgroundColor: "#FFFF89",
            width: 50,
            borderRadius: 4,
          }}
        />
      </div>
      <hr style={{ border: `1px solid #FFFF89`, width: "100%" }} />
      <p
        style={{
          fontSize: 18,
          fontFamily: "Inter",
          lineHeight: "2rem",
        }}
      >
        {description}
      </p>
    </div>
  );
};

export { Default as TextLine };
