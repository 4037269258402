const Default = (props) => {
  const { style, ...other } = props;

  return (
    <p style={{ fontSize: 20, fontFamily: "Inter", ...style }} {...other}>
      +7 (495) 649-86-81
    </p>
  );
};

export { Default as Phone };
