const Default = (props) => {
  const { flex, row, gap, style, grow, center, wrap, ...other } = props;

  const newStyle = {};

  if (flex) {
    newStyle.display = "flex";
    newStyle.flexDirection = "column";
  }
  if (row) {
    newStyle.flexDirection = "row";
  }
  if (gap) {
    newStyle.gap = typeof gap === "boolean" ? 10 : gap;
  }

  if (grow) {
    newStyle.flexGrow = 1;
  }
  if (wrap) {
    newStyle.flexWrap = "wrap";
  }

  if (center) {
    newStyle.alignItems = "center";
    newStyle.justifyContent = "center";
  }

  return <div style={{ ...newStyle, ...style }} {...other} />;
};

export { Default as Box };
