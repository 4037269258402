const Default = (props) => {
  const { description, caption } = props;

  return (
    <div className="info-block">
      <p
        style={{
          fontSize: 20,
          fontWeight: "bold",
          color: "#FFFF91",
          fontFamily: "Inter",
          lineHeight: "2rem",
        }}
      >
        {caption}
      </p>
      <p
        style={{
          fontSize: 18,
          fontFamily: "Inter",
          lineHeight: "2rem",
        }}
      >
        {description}
      </p>
    </div>
  );
};

export { Default as TextBlock };
