import { Box, Button } from "../../components";
import email from "./img/mail.svg";
import phone from "./img/phone.svg";
import whatsapp from "./img/whatsapp.svg";
import telegram from "./img/telegram.svg";

const Default = (props) => {
  const { img } = props;

  return (
    <div
      className="main mainCenter1024"
      name="page4"
      style={{
        flexDirection: "column",
        alignItems: "center",
        display: "flex",
      }}
    >
      <img className="border" src={img} alt="" style={{ width: "100%" }} />
      <div
        className="info-full mainCenter1024"
        style={{
          flexDirection: "row",
          gap: 50,
          alignItems: "stretch",
          maxWidth: "90%",
        }}
      >
        <Box
          className="smallCenter1024"
          flex
          gap={30}
          style={{ maxWidth: 613 }}
        >
          <Box flex row gap={8} style={{ alignItems: "center" }}>
            <img src={`/web_res/img/logo.png`} alt="" width={50} height={50} />
            <p style={{ fontSize: 50, fontFamily: "Tablon" }}>Easily Shop</p>
          </Box>
          <Box className="smallCenter1024" flex row gap={20}>
            <Button caption="Заказать бота" href="#form" />
            <Button caption="Поддержка" href="#form" />
          </Box>
          <Box flex row gap={8} wrap>
            <img src={email} alt="" />
            <p
              style={{ fontFamily: "Inter", fontSize: 18, lineHeight: "2rem" }}
            >
              aleksandarstognushenko@yandex.ru
            </p>
          </Box>
          <Box flex row gap={8}>
            <img src={phone} alt="" />

            <p
              style={{ fontFamily: "Inter", fontSize: 18, lineHeight: "2rem" }}
            >
              +7 (495) 649-86-81
            </p>
          </Box>
          <Box flex row gap={20}>
            <Box
              flex
              center
              style={{
                border: "2px solid #FFFF89",
                minWidth: 80,
                minHeight: 80,
                borderRadius: 4,
              }}
            >
              <a href="https://t.me/Easily_Shop">
                <img src={telegram} alt="" />
              </a>
            </Box>
            <Box
              flex
              center
              style={{
                border: "2px solid #FFFF89",
                minWidth: 80,
                minHeight: 80,
                borderRadius: 4,
              }}
            >
              <a href="tel:+74956498681">
                <img src={whatsapp} alt="" />
              </a>
            </Box>
          </Box>
          <p style={{ fontFamily: "Inter", fontSize: 18, lineHeight: "2rem" }}>
            г. Москва, вн.тер.г. поселение Сосенское, п Коммунарка, ул
            Потаповская Роща, д. 6, к. 1, помещ. 8П
          </p>
        </Box>
        <Box
          className="logo2"
          flex
          row
          grow
          style={{ position: "relative", width: 1110 }}
        >
          <Box
            flex
            grow
            style={{
              position: "absolute",
              height: 680,
              inset: 0,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundImage: `url("/web_res/img/bird2.png")`,
            }}
          />
        </Box>
      </div>
    </div>
  );
};
export { Default as Page4 };
