import { useState } from "react";
import {
  Box,
  Button,
  Caption,
  CheckBox,
  Description,
  Header,
  Input,
  TextBlock,
  TextDot,
  TextLine,
} from "../../components";
import { toast } from "react-toastify";

const arr = {
  name: "Имя",
  lastName: "Фамилия",
  phone: "Телефон",
  question: "Ниша",
};

const Form = () => {
  const [data, setData] = useState({});

  return (
    <div
      className="form"
      id="form"
      style={{
        backgroundColor: "#fff",
        borderRadius: 10,
        padding: 20,
        minWidth: 589,
        width: 589,
        display: "flex",
        flexDirection: "column",
        gap: 30,
      }}
    >
      <Input
        name="name"
        caption="Имя"
        onChange={(e) => {
          setData((p) => {
            p.name = e;
            return { ...p };
          });
        }}
      />
      <Input
        name="lastName"
        caption="Фамилия"
        onChange={(e) => {
          setData((p) => {
            p.lastName = e;
            return { ...p };
          });
        }}
      />
      <Input
        name="phone"
        caption="Телефон"
        onChange={(e) => {
          setData((p) => {
            p.phone = e;
            return { ...p };
          });
        }}
      />
      <Input
        name="question"
        caption="Для какой ниши нужен бот?"
        onChange={(e) => {
          setData((p) => {
            p.question = e;
            return { ...p };
          });
        }}
      />

      <CheckBox caption="Я принимаю пользовательское соглашение" />

      <Button
        caption="Отправить"
        style={{ alignSelf: "center", cursor: "pointer" }}
        onClick={() => {
          toast(
            "Ваш запрос отправлен менеджеру, в ближайшее время с Вами свяжутся",
            {
              position: "top-center",
              autoClose: 5000,
            }
          );
          fetch(
            encodeURI(
              `/sendMessage?text=${Object.keys(data)
                .reduce((p, i) => {
                  p.push(`${arr[i]}: ${data[i]}`);
                  return p;
                }, [])
                .join("\n\n")}`
            )
          ).catch(() => {});
        }}
      />
    </div>
  );
};

const Default = () => {
  return (
    <div className="main mainCenter1024" name="page3">
      <div className="background" />

      <div
        className="hide760"
        style={{
          position: "absolute",
          top: 900,
          left: 0,
          width: 200,
          height: 600,
          backgroundImage: `url("/web_res/img/leave3.png")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      />

      <div className="info-full mainCenter1024">
        <Caption className="center1024" caption="Преимущества:" />
        <div className="info-prem">
          <div className="c1">
            <TextBlock
              caption="Простой интерфейс"
              description="Легкая настройка и управление через удобную админ панель."
            />
            <TextBlock
              caption="Автоматизация процессов"
              description="Бот отвечает на часто задаваемые вопросы, принимает заказы и предоставляет информацию о товарах."
            />
            <TextBlock
              caption="Фрилансеров и специалистов"
              description="Организуйте свои услуги и взаимодействие с клиентами."
            />
          </div>
          <div className="c2">
            <div className="info-block">
              <Header caption="Фиксированная цена" />
              <Header
                className="price"
                caption="35 000₽"
                style={{ fontSize: 90 }}
              />
              <Description caption="Благодаря фиксированной цене процесс становится более прозрачным, а так же позволяет чувствовать стабильность и предсказуемость." />
              <div style={{ flexGrow: 1 }} />
              <Button caption="Заказать" href="#form" />
            </div>
          </div>
        </div>
        <Caption
          className="center1024"
          caption="Заполни заявку на создание бота и получи:"
        />
        <div className="info-prem">
          <Form />
          <Box flex gap={20}>
            <Box flex gap={30}>
              <TextDot caption="Полную настройку под ваши нужды;" />
              <TextDot caption="Наполнение товарами;" />
              <TextDot caption="Техническую поддержку на весь срок использования." />
            </Box>
            <Box grow />
            <Caption
              className="smallCenter1024"
              caption="Не упустите возможность упростить свой бизнес! Заполните форму прямо сейчас."
              simple
            />
          </Box>
        </div>
        <div style={{ width: "90%", marginBottom: 50 }}>
          <Caption
            className="center1024 small"
            caption="часто задаваемые вопросы:"
          />
          <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
            <TextLine
              caption="Как долго занимает настройка бота?"
              description="Настройка занимает от 1 часа и до 2х дней!"
            />
            <TextLine
              caption="Нужно ли мне иметь технические навыки?"
              description="Нет, наш бот интуитивно понятен и прост в использовании."
            />
            <TextLine
              caption="Что делать, если возникнут проблемы?"
              description="Наша команда технической поддержки всегда готова помочь вам!"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export { Default as Page3 };
