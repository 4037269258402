import arrow from "./arrow.svg";

const Default = (props) => {
  const { caption, style, href, ...other } = props;

  const component = (
    <>
      <p
        style={{
          fontSize: 18,
          fontFamily: "Inter",
          color: "#000",
          flexGrow: 1,
          textAlign: "center",
        }}
      >
        {caption}
      </p>
    </>
  );

  return (
    <div
      className="button"
      style={{
        alignSelf: "flex-start",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: 303,
        height: 70,
        borderRadius: 10,
        background:
          "linear-gradient(93.51deg, #FFFF89 -11.32%, #ED9F31 166.26%)",
        ...style,
      }}
      {...other}
    >
      {href ? (
        <a
          href={href}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          {component}
        </a>
      ) : (
        component
      )}
      {href ? (
        <a
          href={href}
          style={{
            background: "#FFFF89",
            height: "100%",
            display: "flex",
            alignItems: "center",
            width: 70,
            borderRadius: 10,
            justifyContent: "center",
          }}
        >
          <div>
            <img src={arrow} alt="" />
          </div>
        </a>
      ) : (
        <div
          style={{
            background: "#FFFF89",
            height: "100%",
            display: "flex",
            alignItems: "center",
            width: 70,
            borderRadius: 10,
            justifyContent: "center",
          }}
        >
          <img src={arrow} alt="" />
        </div>
      )}
    </div>
  );
};

export { Default as Button };
