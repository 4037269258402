const Default = (props) => {
  const { caption } = props;

  return (
    <p
      style={{
        fontSize: 20,
        fontFamily: "Inter",
        lineHeight: "2rem",
      }}
    >
      {caption}
    </p>
  );
};

export { Default as Description };
