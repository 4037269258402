import { Box, Button, Caption, Header, Phone } from "../../components";
import { ReactComponent as Mail } from "./img/mail2.svg";
import { ReactComponent as Phone2 } from "./img/phone2.svg";

const Default = () => {
  return (
    <div
      className="main"
      name="page1"
      style={{ flexDirection: "row", display: "flex" }}
    >
      <div className="background" />

      <div
        className="hide760"
        style={{
          position: "absolute",
          top: 100,
          right: 80,
          width: 120,
          height: 120,
          backgroundImage: `url("/web_res/img/leave1.png")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      />

      <div
        className="hide760"
        style={{
          position: "absolute",
          top: 200,
          left: 0,
          width: 260,
          height: 260,
          backgroundImage: `url("/web_res/img/leave2.png")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      />

      <div
        className="hide1024"
        style={{
          width: 200,
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          paddingLeft: 10,
          zIndex: 1,
        }}
      >
        <Box flex gap={20}>
          <Box
            flex
            center
            style={{
              borderRadius: "50%",
              backgroundColor: "#ffff89",
              width: 40,
              height: 40,
            }}
          >
            <a href="mailto:aleksandarstognushenko@yandex.ru">
              <Mail />
            </a>
          </Box>
          <Box
            flex
            center
            style={{
              borderRadius: "50%",
              backgroundColor: "#ffff89",
              width: 40,
              height: 40,
            }}
          >
            <a href="tel:+74956498681">
              <Phone2 />
            </a>
          </Box>
        </Box>
      </div>

      <div style={{ width: "100%", zIndex: 1, position: "relative" }}>
        <div className="header">
          <Header
            className="hide600 hide760"
            caption="Личный кабинет"
            simple
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open("https://store.easilyshop.ru/root");
            }}
          />
          <div className="header-logo">
            <Box flex row gap={8} style={{ alignItems: "center" }}>
              <img
                src={`/web_res/img/logo.png`}
                alt=""
                width={50}
                height={50}
              />
              <p style={{ fontSize: 50, fontFamily: "Tablon" }}>Easily Shop</p>
            </Box>
            <p
              className="smallHeader"
              style={{ fontSize: 20, fontFamily: "Inter" }}
            >
              Надежный помощник в мире автоматизации
            </p>
          </div>
          <Phone className="hide600 hide760" />
        </div>
        <div
          className="show1024"
          style={{
            display: "none",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 50,
            zIndex: 1,
            marginBottom: 80,
          }}
        >
          <p
            style={{
              fontSize: 20,
              borderRadius: 10,
              background: "#61616166",
              padding: 20,
              fontFamily: "Inter",
              width: 522,
              lineHeight: "2rem",
              textAlign: "center",
              borderImageSource:
                "linear-gradient(90.78deg, #0F231D -6.13%, #A2A2A2 49.31%, #0F231D 104.74%)",

              backdropFilter: "blur(16.6px)",
            }}
          >
            Бот интегрируется с Telegram, позволяя принимать заявки на продажи
            напрямую.
          </p>
        </div>
        <div
          className="mainLogoText"
          style={{
            zIndex: 4,
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
            gap: 10,
          }}
        >
          <Caption
            className="center1024"
            caption="Создай бота"
            simple
            style={{ fontSize: 90 }}
          />
          <Caption
            className="center1024"
            caption="за считанные минуты!"
            simple
          />
          <Button className="center1024" caption="Заказать" href="#form" />
          <p
            className="hide1024"
            style={{
              fontSize: 20,
              borderRadius: 10,
              background: "#61616166",
              padding: 20,
              fontFamily: "Inter",
              alignSelf: "flex-end",
              marginRight: 150,
              width: 522,
              lineHeight: "2rem",
              textAlign: "center",
              marginTop: 40,
              borderImageSource:
                "linear-gradient(90.78deg, #0F231D -6.13%, #A2A2A2 49.31%, #0F231D 104.74%)",

              backdropFilter: "blur(16.6px)",
            }}
          >
            Бот интегрируется с Telegram, позволяя принимать заявки на продажи
            напрямую.
          </p>
        </div>
        <div className="logo">
          <img
            className="logoCenter1024"
            src="/web_res/img/cvetok.png"
            alt=""
            style={{ filter: "brightness(0.7) contrast(1.5)", height: 1400 }}
          />
          <img
            className="logo2Center1024"
            src="/web_res/img/ptic.png"
            alt=""
            style={{
              filter: "brightness(0.7) contrast(1.5)",
              position: "absolute",
              top: -60,
              left: 120,
              width: 800,
            }}
          />
        </div>
      </div>
    </div>
  );
};
export { Default as Page1 };
