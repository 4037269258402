const Default = (props) => {
  const { caption, style, simple, ...other } = props;

  return (
    <p
      style={
        simple
          ? { fontSize: 20, fontFamily: "Inter", ...style }
          : {
              fontSize: 20,
              fontWeight: "bold",
              fontFamily: "Tablon bold",
              color: "#FFFF91",
              lineHeight: "2rem",
              ...style,
            }
      }
      {...other}
    >
      {caption}
    </p>
  );
};

export { Default as Header };
