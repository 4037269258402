import { Caption, TextBlock } from "../../components";

const Default = (props) => {
  const { img } = props;

  return (
    <div
      className="main mainCenter1024"
      name="page2"
      // style={{ marginTop: -200 }}
    >
      <img
        className="border1"
        src={img}
        alt=""
        // style={{ transform: "translateY(130px)", width: "100%" }}
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          top: "var(--top)",
          width: "100%",
        }}
      />
      <div className="info-full mainCenter1024">
        <Caption
          className="center1024"
          caption="Этот бот идеально подойдет для:"
        />
        <div className="info">
          <TextBlock
            caption="Малого и среднего бизнеса"
            description="Упрощайте общение с клиентами и увеличивайте продажи."
          />
          <TextBlock
            caption="Интернет-магазина"
            description="Автоматизируйте процесс заказа и обслуживания клиентов."
          />
          <TextBlock
            caption="Фрилансеров и специалистов"
            description="Организуйте свои услуги и взаимодействие с клиентами."
          />
          <TextBlock
            caption="Сервисной компании"
            description="Обеспечьте быструю обратную связь и поддержку."
          />
        </div>
      </div>
      <img className="border-niz" src={img} alt="" style={{ width: "100%" }} />
    </div>
  );
};
export { Default as Page2 };
