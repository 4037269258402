import "./style.css";
import borderTop from "./img.svg";
import { Page1 } from "./page1.js";
import { Page2 } from "./page2.js";
import { Page3 } from "./page3.js";
import { Page4 } from "./page4.js";
import { Page5 } from "./page5.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Default = (props) => {
  return (
    <>
      <Page1 />
      <Page2 img={borderTop} />
      <Page3 />
      <Page4 img={borderTop} />
      <Page5 />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export { Default as Lending };
